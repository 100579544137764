import React from "react"
import { Link } from "gatsby"
import close from "../../svg/close_icon.svg"

const InvestorPopup = props => {
  return (
    <div className="lg:w-80 w-7/12 hidden	fixed shadow-2xl bg-white text-blue-400 bottom-10 lg:right-5 right-20 z-50 p-2 sm:p-4 rounded-large hover:text-white hover:bg-blue-400 flex items-center justify-around sm:justify-center flex-col sm:flex-row">
      <div onClick={() => props.setInvestPopup(false)}>
        <img
          src={close}
          alt="close"
          className="absolute right-4 sm:hidden block"
        />
      </div>
      <Link to="/investor-page" className="">
        <div className="text-center static mx-auto flex flex-col sm:flex-row items-center justify-center">
          <span className="mb-1 sm:mb-0">Fund Raising</span>
          <span className="mx-1.5 hidden sm:block">|</span>
          <span>EIS & SEIS Ready 🚀</span>
        </div>
      </Link>
      <div
        onClick={() => props.setInvestPopup(false)}
        className="hidden sm:block"
      >
        <img src={close} alt="close" className="m-0" />
      </div>
    </div>
  )
}

export default InvestorPopup
